html {
  background-color: #b3efff;
  display: flex;
  justify-content: center;  
  min-height: 100vh;
}

.App {
  text-align: center;  
}

.App-logo {
  height: 8vmin;
  margin: 50px;
  pointer-events: none;
}

.App-header {
  background-color: #b3efff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #24262d;
}

.smaller {
  font-size: calc(10px + 1vmin)
}

.App-link {
  /* color: #61dafb; */
  background: #f0c14b;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  padding: 10px 20px;
  margin-top: 30px;
  text-decoration: none;
  font-size: 80%;
  border-radius: 10px;  
}

.App-cart {
  height: calc(20px + 1vmin);
  vertical-align: middle;
  margin-right: 15px;
}

.brand {
  text-decoration: none;  
  color: #24262d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;  
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;  
  margin-right: 50px;
}

.brand img {  
  margin-bottom: 10px;
}